<script setup lang="ts">
import { CheckCircle2 } from "lucide-vue-next";
import { useBookingStore } from "~/store";
import AppStore from "~/assets/svg/appstore.svg";
import GooglePlay from "~/assets/svg/googleplay.svg";

const { form, getContactNumber, createdBooking } = useBookingStore();
const route = useRoute();
const { postMessage } = useReactNativeWebView();

function viewBooking(bookingId: string) {
  postMessage("view_booking", { booking_id: bookingId });
}
</script>

<template>
  <CheckCircle2 class="fill-success stroke-white mb-4" :size="48" />
  <h1 class="mb-2">
    Thank you {{ form.first_name }}, your booking is now complete
  </h1>
  <p class="text-muted-foreground font-light">
    Your booking has been received and we are finding you the perfect cleaner!
  </p>
  <p class="text-muted-foreground font-light mt-2">
    In the meantime, if you have any questions, feel free to reach out to our
    friendly customer service team on
    <a :href="`tel:${getContactNumber()}`" class="text-primary">
      {{ getContactNumber() }}
    </a>
  </p>
  <hr class="my-8" />
  <template v-if="route.query.embed">
    <Button
      type="submit"
      @click="viewBooking(createdBooking.unique_id)"
      size="lg"
      class="w-full"
    >
      View Booking
    </Button>
  </template>
  <template v-else>
    <h3>Get the app</h3>
    <p class="mt-2 font-light">
      Manage your cleaning jobs, reschedule services and chat with your cleaner
      from our absolutely amazing app.
    </p>
    <div class="flex sm:gap-4 gap-2 mt-8 mb-12 flex-wrap">
      <a
        href="https://apps.apple.com/au/app/myabdom/id1553742811?itsct=apps_box_badge&itscg=30200"
      >
        <AppStore
          class="w-32 sm:w-48 hover:opacity-65 transition-opacity duration-150 ease-in-out"
          :fontControlled="false"
          filled
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.absolutedomestics.myabdom&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      >
        <GooglePlay
          class="w-32 sm:w-48 hover:opacity-65 transition-opacity duration-150 ease-in-out"
          :fontControlled="false"
          filled
        />
      </a>
    </div>
  </template>
  <section></section>
</template>
