<script setup lang="ts">
import { useBookingStore } from "~/store";
import {
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_CASH,
} from "~/lib/constants";
import { loadStripe } from "@stripe/stripe-js";
import { ref, watch } from "vue";
import { LockIcon } from "lucide-vue-next";
import CreditCard from "~/assets/svg/credit-card.svg";
import Bank from "~/assets/svg/bank.svg";
import PoweredByStripe from "~/assets/svg/powered-by-stripe.svg";
import Visa from "~/assets/svg/visa.svg";
import Mastercard from "~/assets/svg/mastercard.svg";
import Amex from "~/assets/svg/amex.svg";
import BookingSummary from "~/components/form/BookingSummary.vue";

const { form, canUseCreditCard, canUseCash, usingCreditCard} = useBookingStore();
const runtimeConfig = useRuntimeConfig();

const stripeKey = ref(runtimeConfig.public.auStripeKey);
const stripe = ref(null);
const elements = ref(null);
const cardNumber = ref(null);
const cardExpiry = ref(null);
const cardCvc = ref(null);

watch(
  () => form.payment_method,
  (value) => {
    if (value === PAYMENT_METHOD_CREDIT_CARD) {
      setupStripe();
    }
  }
);

onMounted(() => {
  if (usingCreditCard()) {
    setupStripe();
  }
});


const paymentOptions = () => {
	const options = []

	if (canUseCash()) {
		options.push(PAYMENT_METHOD_CASH)
	}

	if (canUseCreditCard()) {
		options.push(PAYMENT_METHOD_CREDIT_CARD)
	}

	return options
}

watch(
	() => paymentOptions(),
	(value) => {
		if (value.length === 1) {
			form.payment_method = value[0]
			return;
		}

		if ([PAYMENT_METHOD_CREDIT_CARD, PAYMENT_METHOD_CASH].includes(form.payment_method)) {
			return;
		}

		// If credit card is enabled and the user hasn't selected a payment method yet, default to credit card
		if (value.includes(PAYMENT_METHOD_CREDIT_CARD) && !form.payment_method) {
			form.payment_method = PAYMENT_METHOD_CREDIT_CARD
			return;
		}

		form.payment_method = null
	},
	{
		immediate: true,
	}
);

const setupStripe = async () => {
  stripe.value = await loadStripe(stripeKey.value);

  const style = {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };

  elements.value = stripe.value.elements();

  cardNumber.value = elements.value.create("cardNumber", { style });
  cardNumber.value.mount("#card-number");

  cardExpiry.value = elements.value.create("cardExpiry", { style });
  cardExpiry.value.mount("#card-expiry");

  cardCvc.value = elements.value.create("cardCvc", { style });
  cardCvc.value.mount("#card-cvc");
};

const beforeSubmit = async () => {
  return new Promise(async (resolve, reject) => {
    if (form.payment_method === PAYMENT_METHOD_CREDIT_CARD) {
      const { token, error } = await stripe.value.createToken(cardNumber.value);

      if (error) {
        reject(error);
        return;
      }

      form.token = token;
      resolve(token);
    }
  });
};

defineExpose({ beforeSubmit });
</script>

<template>
  <h1 class="leading-none lg:mb-8 mb-4">Complete Booking</h1>
	<template v-if="paymentOptions().length === 1 && paymentOptions()[0] === PAYMENT_METHOD_CASH">
		<p class="text-muted-foreground pb-6">
			Cleaners are paid by cash or bank transfer on the day of the clean. Please confirm the details of your
			booking and click "Submit" to confirm your booking.
		</p>
	</template>
  <section>
    <FormField v-slot="{ componentField }" name="payment_method">
      <FormItem v-auto-animate>
        <FormLabel  v-if="paymentOptions().length > 1">
          Choose your payment method and
          <span class="text-primary">pay on the day</span>
        </FormLabel>
        <FormDescription v-if="usingCreditCard()">
          Please note that your card on file will not be charged until 24 hours after your cleaning is completed, and you will receive a notification from your cleaner once the service is finished.
        </FormDescription>
        <FormControl  v-if="paymentOptions().length > 1">
          <ToggleGroup
            v-bind="componentField"
            type="single"
            class="grid lg:grid-cols-2 grid-cols-1 lg:gap-x-4 lg:gap-y-0 gap-y-4 items-end w-full"
            v-model="form.payment_method"
          >
            <ToggleGroupItem
							v-if="canUseCreditCard()"
              :value="PAYMENT_METHOD_CREDIT_CARD"
              class="payment-method relative h-full"
              :class="{
                active: form.payment_method === PAYMENT_METHOD_CREDIT_CARD,
              }"
            >
              <div
                class="absolute top-0 left-0 w-full bg-primary/5 rounded-t-md py-1 uppercase font-mono tracking-widest"
              >
                popular
              </div>
              <div
                class="flex flex-col justify-center items-center gap-y-1 mt-4 -mb-2"
              >
                <CreditCard class="w-8 mb-2" :fontControlled="false" filled />
                <h3>Debit / Credit Card</h3>
                <p class="font-light">*Includes service fee</p>
              </div>
            </ToggleGroupItem>
            <ToggleGroupItem
							v-if="canUseCash()"
              :value="PAYMENT_METHOD_CASH"
              class="payment-method h-30"
              :class="{
                active: form.payment_method === PAYMENT_METHOD_CASH,
              }"
            >
              <div class="flex flex-col justify-center items-center gap-y-1">
                <Bank class="w-8 mb-2" :fontControlled="false" filled />
                <h3>Cash or Bank Transfer</h3>
              </div>
            </ToggleGroupItem>
          </ToggleGroup>
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormField>
    <div
      v-if="form.payment_method === PAYMENT_METHOD_CREDIT_CARD"
      v-auto-animate
      class="mb-2"
    >
      <hr class="my-8" />
      <div class="grid gap-4">
        <div class="space-y-2">
          <label>Card Number*</label>
          <div class="relative">
            <div id="card-number" class="StripeElement col-span-2"></div>
            <div class="absolute top-4 right-3 md:right-5 md:top-5">
              <div class="flex">
                <Visa class="h-4 md:h-6" filled :fontControlled="false" />
                <Mastercard
                  class="h-4 md:mr-2 md:h-6"
                  filled
                  :fontControlled="false"
                />
                <Amex class="h-4 md:h-6" filled :fontControlled="false" />
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="space-y-2">
            <label>Expiry Date*</label>
            <div id="card-expiry" class="StripeElement"></div>
          </div>
          <div class="space-y-2">
            <label>CVC*</label>
            <div id="card-cvc" class="StripeElement"></div>
          </div>
        </div>
      </div>
      <div class="my-12"></div>
      <div
        class="flex justify-center items-center gap-x-2 text-muted-foreground text-sm md:text-md"
      >
        <LockIcon class="w-4 md:w-4 text-muted-foreground" />
        Safe and Secure Payments
        <div>
          <PoweredByStripe class="w-24 lg:w-32" :fontControlled="false" />
        </div>
      </div>
    </div>
    <div
      class="mt-6"
      :class="{
        'mt-10': form.payment_method === PAYMENT_METHOD_CASH,
      }"
    ></div>
  </section>
</template>

<style>
button.payment-method {
  @apply w-full flex flex-col items-center justify-center;
  @apply selectable;

  &:disabled {
    opacity: 100;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @apply rounded-t-md;
      background-color: rgba(255, 255, 255, 0.75);
      z-index: 1;
    }
  }
}

.StripeElement {
  @apply bg-background rounded-md border border-input py-[13.4px] lg:py-[21.4px] px-6 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 transition-all duration-150 ease-in-out;

  &.StripeElement--complete {
    @apply bg-primary-light border-primary-soft;
  }
}

.StripeElement--focus {
  @apply !border-primary ring-4 ring-primary-light;
}
</style>
