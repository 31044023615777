<template>
  <div class="h-screen flex items-center justify-center">
		<div>
			<h1 v-if="error.statusCode" class="text-destructive font-medium">Error</h1>
			<h2 class="text-lg mt-2">
				We're sorry, something has gone very wrong.
			</h2>
			<h3 class="text-md mt-3">
				Please call us on <a :href="`tel:${getContactNumber()}`" class="text-primary text-lg align-middle font-semibold">{{ getContactNumber() }}</a> to continue your booking.
			</h3>
			<div class="mt-2">
				<NuxtLink to="/" class="flex items-center gap-x-2 text-muted-foreground">
					<ArrowLeft size="16" /> Go Back
				</NuxtLink>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ArrowLeft } from 'lucide-vue-next';
import { useBookingStore } from './store';

const { getContactNumber } = useBookingStore();

defineProps({
  error: Object
})
</script>
