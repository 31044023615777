import { ref } from "vue";

export function useReactNativeWebView() {
  const isWebViewAvailable = ref(
    typeof window !== "undefined" &&
      typeof window.ReactNativeWebView !== "undefined",
  );

  const postMessage = (type: string, payload: Record<string, any> = {}) => {
    if (!isWebViewAvailable.value) {
      console.warn("ReactNativeWebView is not available.");
      return;
    }

    const message = {
      type,
      ...payload,
    };

    try {
      window.ReactNativeWebView?.postMessage(JSON.stringify(message));
    } catch (error) {
      console.error("Failed to send message to WebView:", error);
    }
  };

  return {
    isWebViewAvailable,
    postMessage,
  };
}
