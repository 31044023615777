<script setup lang="ts">
import { useBookingStore } from "~/store/";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/yup";
import * as yup from "yup";
import { toast } from "~/components/ui/toast";
import { useReactNativeWebView } from "~/composables/useReactNativeWebView";
import {
  REGULAR,
  ONEOFF,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_CASH,
  FREQUENCY_WEEKLY,
} from "~/lib/constants";
import * as Sentry from "@sentry/vue";
import { isValidPhoneNumber, type CountryCode } from "libphonenumber-js";

const {
  form,
  submit,
  nextStep,
  country,
  isNZ,
  isRegularEnabled,
  isOneoffEnabled,
  canUseCreditCard,
	canUseCash,
  getContactNumber,
  setIsNDIS,
  setService,
} = useBookingStore();
const HTMLForm = ref<null | any>(null);
const step5 = ref<null | any>(null);
const showSuburbNotFoundDialog = ref(false);
const route = useRoute();
const router = useRouter();
const { isWebViewAvailable, postMessage } = useReactNativeWebView();

const schema = [
  yup.object().shape({
    suburb: yup
      .object()
      .typeError("Please select a suburb")
      .required("Please select a suburb"),
  }),
  yup.object().shape({
    service: yup.string().required("Please select a service"),
    frequency: yup
      .string()
      .nullable()
      .when("$service", {
        is: (service: any) => service === REGULAR,
        then: () => yup.string().required("Please select a frequency"),
      }),
    days: yup
      .string()
      .nullable()
      .when("$frequency", {
        is: (frequency: any) => frequency === FREQUENCY_WEEKLY,
        then: () =>
          yup
            .string()
            .nonNullable()
            .required("Please select the number of days"),
      }),
    is_ndis: yup.number().oneOf([0, 1]).required("Please select an option"),
    oneoff_type: yup
      .string()
      .nullable()
      .when("$service", {
        is: (service: any) => service === ONEOFF,
        then: () =>
          yup.string().nonNullable().required("Please select a one-off type"),
      })
      .nullable(),
  }),
  yup.object().shape({
    hours: yup.string().required("Please select the number of hours"),
    preferred_days: yup
      .array()
      .nullable()
      .when("$service", {
        is: (service: any) => service === REGULAR,
        then: () => {
          return yup
            .array()
            .nonNullable()
            .required("Please select at least one preferred day")
            .min(1, "Please select at least one preferred day");
        },
      })
			.when("$days", ([days], schema) => {
					const parsedDays = parseInt(days, 10);

					if (parsedDays > 1) {
						return schema
							.nonNullable()
							.min(parsedDays, `Please select at least ${parsedDays} preferred days`)
					}

					return schema;
			}),
    start_date: yup
      .date()
      .nullable()
      .default(null)
      .required("Please select a start date"),
    message: yup.string().nullable(),
  }),
  yup.object().shape({
    first_name: yup.string().required("Please enter your first name"),
    last_name: yup.string().required("Please enter your last name"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Please enter your email address"),
    phone: yup
      .string()
      .required("Please enter your phone number")
      .nullable()
      .test(
        "isValidPhoneNumber",
        "Please enter a valid phone number",
        (value: any) => {
          return isValidPhoneNumber(value || "", country as CountryCode);
        },
      ),
    address: yup.string().required("Please enter your address"),
    address_suburb: yup.string().required("Please enter your suburb"),
    address_state: yup.string().required("Please enter your state"),
    address_postcode: yup.string().required("Please enter your postcode or area name"),
  }),
  yup.object().shape({
    payment_method: yup
      .string()
      .oneOf([PAYMENT_METHOD_CREDIT_CARD, PAYMENT_METHOD_CASH])
      .required("Please select a payment method"),
  }),
  yup.object().shape({}),
];

const currentSchema = computed(() =>
  toTypedSchema(schema[form.step - 1], {
    context: {
      ...form,
    },
    abortEarly: false,
  }),
);

const { handleSubmit, setFieldValue, values } = useForm({
  validationSchema: currentSchema,
  initialValues: {
    ...form,
  },
  keepValuesOnUnmount: true,
});

function handleSuburbSelected() {
  doFormSubmission();
}

onBeforeMount(async () => {
  if (isNZ()) {
    setIsNDIS(0);
  }

  const route = useRoute();

  if (route.query.suburb_id) {
    await useApi()
      .get(`/suburbs/${route.query.suburb_id}`)
      .then((response) => {
        const suburb = response.data.data;
        form.suburb = suburb;
        form.address_suburb = suburb.name;
        form.address_state = suburb.state;
        form.address_postcode = suburb.postcode;
        doFormSubmission();
      });
  }

  if (route.query.service && form.service === null) {
    setService(route.query.service);
  }

  if (route.query.uuid) {
    loadForm();
  }
});

const saveForm = () => {
  useApi().put(`/booking-form/${route.query.uuid}`, {
    ...form,
    ...{
      days: parseInt(form.days),
      is_ndis: parseInt(form.is_ndis),
    },
    ...{
      suburb_id: form.suburb?.id,
      step: form.step,
      service: form.service,
    },
  });
};

const createForm = () => {
  useApi()
    .post(`/booking-form`, {
      suburb_id: form.suburb?.id,
      step: 2,
    })
    .then((response) => {
      const query = { ...route.query, uuid: response.data.uuid };
      router.push({ query });

      if (isWebViewAvailable) {
        postMessage("set_uuid", { uuid: response.data.uuid });
      }
    });
};

const loadForm = () => {
  useApi()
    .get(`/booking-form/${route.query.uuid}`)
    .then((response) => {
      form.suburb = response.data.suburb;
      form.step = response.data.step;
      form.service = response.data.service;
      Object.keys(response.data.data).forEach((key: string) => {
        form[key] = response.data.data[key];
      });
    })
    .catch(() => {
      const query = { ...route.query };
      delete query.uuid;
      router.push({ query });
    });
};

const handleFormSubmit = (event: Event) => {
  event.preventDefault();

  if (form.step === 1) {
    return;
  }

  doFormSubmission();
};

const doFormSubmission = handleSubmit(
  async () => {
    if (form.step === 1) {
      if (!isRegularEnabled() && !isOneoffEnabled()) {
        showSuburbNotFoundDialog.value = true;
        return;
      }

      createForm();
    }

    if (form.step === 4) {
      Sentry.setUser({
        name: `${form.first_name} ${form.last_name}`,
        email: form.email!,
        phone: form.phone,
      });
    }

    if (form.step === 5) {
      if (form.payment_method === PAYMENT_METHOD_CREDIT_CARD) {
        try {
          await step5.value?.beforeSubmit();
        } catch (error) {
          return;
        }
      }

      try {
        await submit();
      } catch (error) {
        Sentry.captureException(error);
        return;
      }
    }

    nextStep();

    if (route.query.uuid) {
      saveForm();
    }

    nextTick(() => {
      window.scrollTo({
        top: 0,
      });
    });
  },
  () => {
    const nextError = document.querySelector("h3.invalid");
    if (nextError) {
      nextError.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    toast({
      title: "Please complete all required fields before proceeding",
      variant: "destructive",
    });
  },
);

/**
 * Ensure that the internal form values are in sync with the form object, specifically
 * when the form object is updated when the form field is not mounted
 */
watch(
  () => form,
  () => {
    Object.keys(values).forEach((key) => {
      if (values[key] !== form[key]) {
        setFieldValue(key, form[key]);
      }
    });
  },
  { deep: true },
);

watch(
  () => form.service,
  (value) => {
    if (value) {
      router.push({ query: { ...route.query, service: value.toString() } });

      if (value !== ONEOFF) {
        form.oneoff_type = null;
      }
    }
  },
  { immediate: true },
);

watch(
  () => form.step,
  (value) => {
    if (value) {
      router.push({ query: { ...route.query, step: value.toString() } });

      if (isWebViewAvailable) {
        postMessage("set_step", { step: value });
      }
    }
  },
  { immediate: true },
);
</script>

<template>
  <div
    class="xl:hidden w-full block mb-4 font-light flex-1"
    v-if="form.step <= 5"
  >
    Step {{ form.step }} / 5
  </div>
  <div class="flex justify-between grow gap-x-8 xl:gap-x-16">
    <div class="xl:w-[180px] hidden xl:block">
      <h3 class="lg:mb-4">Book Your Clean</h3>
      <FormSteps :schema="schema" />
    </div>
    <div class="xl:max-w-[560px] grow">
      <form
        id="form"
        ref="HTMLForm"
        @submit.prevent="handleFormSubmit"
        v-auto-animate
      >
        <template v-if="form.step === 1">
          <FormLocation @suburb-selected="handleSuburbSelected" ref="step-1" />
        </template>
        <template v-if="form.step === 2">
          <FormCleaningService ref="step-2" />
        </template>
        <template v-if="form.step === 3">
          <FormAboutTheClean ref="step-3" />
        </template>
        <template v-if="form.step === 4">
          <FormYourDetails ref="step-4" />
        </template>
        <template v-if="form.step === 5">
          <FormCompleteBooking ref="step5" />
        </template>
        <template v-if="form.step === 6">
          <FormCompleted />
        </template>
        <FormErrors />
        <FormControls ref="controls" v-if="form.step <= 5" />
      </form>
    </div>
    <div class="hidden lg:block lg:w-[340px]">
      <FormBookingSummary />
    </div>
  </div>
  <Dialog :open="showSuburbNotFoundDialog">
    <DialogContent
      class="lg:p-8 lg:px-16 lg:w-[588px]"
      @close="
        () => {
          showSuburbNotFoundDialog = false;
        }
      "
    >
      <DialogHeader class="space-y-4">
        <DialogTitle class="text-center text-[20px] font-medium">
          We’re sorry!
        </DialogTitle>
        <DialogDescription class="text-center text-[16px]">
          We don't currently offer any services in your area.
          <br />If you would like to know more, please call our friendly team on
          <a :href="`tel:${getContactNumber()}`" class="text-primary">
            {{ getContactNumber() }}
          </a>
        </DialogDescription>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>
