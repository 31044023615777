<script setup lang="ts">
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useBookingStore } from "~/store/";
import { CheckIcon } from "lucide-vue-next";
import type { ObjectSchema } from "yup";

const { form } = useBookingStore();

const steps = ref<Record<number, string>>({
  1: "Location",
  2: "Cleaning Service",
  3: "About the Clean",
  4: "Your Details",
  5: "Complete Booking",
});

const props = defineProps<{
  schema: ObjectSchema<Record<string, any>>[];
}>();

const valid = ref<Record<number, boolean>>({});

const validateStep = async (step: number) => {
  const schema = props.schema[step - 1];
  const isValid = await schema.isValid(
    { ...form },
    {
      context: {
        $service: form.service,
        $frequency: form.frequency,
				$days: form.days,
      },
    }
  );
  valid.value[step] = isValid;
};

watch(
  () => form,
  (value) => {
    Object.keys(steps.value).forEach((step) => validateStep(Number(step)));
  },
  { deep: true, immediate: true }
);
</script>

<template>
  <RadioGroup class="gap-y-3" v-model="form.step" disabled>
    <div
      class="flex items-center space-x-2"
      v-for="(title, step) in steps"
      :key="`step-${step}`"
    >
      <div
        class="step relative flex items-center justify-center transition-all duration-300"
        :class="{
          complete: valid[step] === true,
          'opacity-50': valid[step] === true && step >= form.step,
        }"
      >
        <RadioGroupItem id="location" :value="step" />
        <CheckIcon class="absolute inset-0 m-auto w-2 h-2 text-white" />
      </div>
      <Label class="text-md" for="location">{{ title }}</Label>
    </div>
  </RadioGroup>
</template>

<style>
[role="radiogroup"] {
  label {
    @apply peer-disabled:opacity-100;
  }
}

.step {
  > svg {
    @apply hidden;
  }

  > button[role="radio"] {
    @apply bg-primary-light border-primary-soft disabled:opacity-100 disabled:cursor-default;
  }

  &.complete {
    @apply text-success;

    > button[role="radio"] {
      @apply bg-success ring-2 ring-success/20 border-none text-transparent;
    }

    > svg {
      @apply block;
    }
  }
}
</style>
