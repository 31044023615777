<script setup lang="ts">
import { ref, watch } from "vue";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { captureException } from "@sentry/vue";
import { useBookingStore } from "~/store";
import { urlify } from "~/lib/utils";
import { CheckIcon } from "lucide-vue-next";
import axios from "axios";

const { form, country } = useBookingStore();
const search = ref<string | null>(null);
const suburbs = ref<any[]>([]);
const cancelTokenSource = ref(axios.CancelToken.source());

const fetchSuburbs = (query: string) => {
  search.value = query.trim() || null;

  if (!search.value || search.value.length <= 2) {
    return;
  }

  if (cancelTokenSource.value) {
    cancelTokenSource.value.cancel("Operation canceled due to new request.");
  }

  cancelTokenSource.value = axios.CancelToken.source();

  useApi()
    .get(`suburbs/list/${urlify(query || "")}?country=${country}`, {
      cancelToken: cancelTokenSource.value.token,
    })
    .then((response) => {
      suburbs.value = response.data;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return;
      }

      captureException(error);
    });
};

const emit = defineEmits(["suburbSelected"]);

const handleSelectSuburb = (suburb: any, doEmit = false) => {
  form.suburb = suburb;
  form.address_suburb = suburb.name;
  form.address_state = suburb.state;
  form.address_postcode = suburb.postcode;

  emit("suburbSelected");
};
</script>

<template>
  <h1 class="leading-none lg:mb-8">Location</h1>
  <FormField v-slot="{ componentField }" name="suburb">
    <FormItem v-auto-animate>
      <FormLabel> Where are you located? </FormLabel>
      <FormControl>
        <Command
          v-bind="componentField"
          @update:model-value="(suburb: any) => handleSelectSuburb(suburb)"
        >
          <CommandInput
            autocomplete="off"
            name="suburb"
            placeholder="Enter your postcode or area name"
            @input="($event) => fetchSuburbs($event.target.value)"
          />
          <CommandList
            class="relative transition-all duration-150 ease-in-out rounded-lg p-0 mt-4"
          >
            <CommandGroup class="m-0 p-0">
              <CommandItem
                class="py-4 px-4 h-full"
                :class="{
                  'bg-primary/05 text-primary selected':
                    form.suburb?.id === suburb.id,
                }"
                v-for="suburb in suburbs?.slice(0, 10)"
                :key="suburb.id"
                :value="suburb"
              >
                <div class="flex justify-between items-center w-full">
                  <div>
                    <span v-if="suburb.name" class="font-semibold mr-2">
                      {{ suburb.name }}
                    </span>
                    <span v-if="suburb.postcode" class="font-light"
                      >({{ suburb.postcode }})</span
                    >
                  </div>
                  <div v-if="form.suburb?.id === suburb.id">
                    <CheckIcon
                      class="w-6 h-6 text-primary hover-within:text-white"
                    />
                  </div>
                </div>
              </CommandItem>
            </CommandGroup>
            <CommandEmpty v-if="search?.length >= 3 && suburbs.length <= 0">
              No suburb found.
            </CommandEmpty>
          </CommandList>
        </Command>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>
</template>
